@import '@/styles/common.scss';

#routeDiv {
    width: 100%;
    height: 250px;
}

.walkCfgRow {
    margin-left: 40px;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.walkCfgCol {
    height: 100%;
    width: 100%;
}

.bottomDivWalk {
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cruiseCfgRow {
    margin-left: 20px;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bottomDivCruise {
    margin-top: 20px;
    margin-left: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
